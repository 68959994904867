import p5 from 'p5'
import "./styles.css";

import PartySocket from "partysocket";

declare const PARTYKIT_HOST: string;

const partySocket = new PartySocket({
  host: PARTYKIT_HOST,
  room: "my-new-room",
});

export type DrawEvent = {
  type: "draw",
  data: {
    x: number,
    y: number,
    colour: p5.Color,
  }
}


function drawTheThing(p: p5, drawEvent: DrawEvent) {

  p.push()
  p.fill((drawEvent.data.colour as any).levels)
  p.ellipse(drawEvent.data.x, drawEvent.data.y, 20, 20)
  p.pop()

}

new p5((p: p5) => {

  partySocket.addEventListener("message", (event) => {

    const drawEvent = JSON.parse(event.data)

    if (drawEvent.type === "draw") {

      drawTheThing(p, drawEvent)

    }

    if (drawEvent.type === "init") {

      drawEvent.data.forEach((drawEvent: DrawEvent) => {

        drawTheThing(p, drawEvent)

      })
    }

  })

  const colour = p.color(255 * Math.random(), 255 * Math.random(), 255 * Math.random())

  p.setup = () => {

    p.createCanvas(window.innerWidth, window.innerHeight);

  }

  p.draw = () => {

    // p.background(255 * Math.random())

  }

  p.mouseDragged = () => {

    const drawEvent: DrawEvent = {
      type: "draw",
      data: {
        x: p.mouseX,
        y: p.mouseY,
        colour,
      }
    }

    drawTheThing(p, drawEvent)
    partySocket.send(JSON.stringify(drawEvent))

  }

})